import React from "react";
import styled from "styled-components";

const List = ({ data }) => {
  return (
    <StyledUl>
      {data.map((item, index) => (
        <li key={item.text + index}>
          <span>{item.text}</span>
        </li>
      ))}
    </StyledUl>
  );
};

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--gray-color);
  padding: 1.2rem 2rem;
  border-radius: 3px;
  li {
    line-height: 2rem;
  }
  span {
    position: relative;
    margin-left: 1.5rem;
    &::before {
      position: absolute;
      content: "";
      width: 0.7rem;
      height: 0.7rem;
      top: 50%;
      left: -1.5rem;
      /* border: 1px solid var(--sub-color); */
      background-color: var(--gray-color);
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }
`;
export default List;
