import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import LayoutForOrder from "../components/LayoutForOrder";
import List from "../components/parts/userGuide/List";
import { Seo } from "../components/seo";
import { StyledOrderH2, StyledOrderH3, StyledOrderP } from "../styled/SText";

const PraivacyPolicy = ({ location }) => {
  const toclist = [
    {
      title: "個人情報保護方針",
      url: "#a",
      items: [
        { title: "個人情報の管理", url: "#b" },
        { title: "個人情報の利用目的", url: "#c" },
        { title: "個人情報の第三者への開示・提供の禁止", url: "#d" },
        { title: "個人情報の安全対策", url: "#e" },
        { title: "ご本人の照会", url: "#f" },
        { title: "法令、規範の遵守と見直し", url: "#g" },
        { title: "お問い合せ", url: "#h" },
      ],
    },
  ];
  return (
    <LayoutForOrder
      mainTitle="プライバシーポリシー"
      subTitle="privacy policy"
      panTitle="プライバシーポリシー"
      panLocation={location}
      tocData={toclist}
    >
      <StyledOrderH2 id={toclist[0].url.slice(1)}>
        個人情報保護方針
      </StyledOrderH2>
      <StyledOrderP>
        当社は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[0].url.slice(1)}>
        個人情報の管理
      </StyledOrderH3>
      <StyledOrderP>
        当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[1].url.slice(1)}>
        個人情報の利用目的
      </StyledOrderH3>
      <StyledOrderP>
        お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[2].url.slice(1)}>
        個人情報の第三者への開示・提供の禁止
      </StyledOrderH3>
      <StyledOrderP>
        当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
      </StyledOrderP>
      <List
        data={[
          { text: "お客さまの同意がある場合" },
          {
            text: "お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合",
          },
          { text: "法令に基づき開示することが必要である場合" },
        ]}
      />
      <StyledOrderH3 id={toclist[0].items[3].url.slice(1)}>
        個人情報の安全対策
      </StyledOrderH3>
      <StyledOrderP>
        当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[4].url.slice(1)}>
        ご本人の照会
      </StyledOrderH3>
      <StyledOrderP>
        お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[5].url.slice(1)}>
        法令、規範の遵守と見直し
      </StyledOrderH3>
      <StyledOrderP>
        当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
      </StyledOrderP>
      <StyledOrderH3 id={toclist[0].items[6].url.slice(1)}>
        お問い合せ
      </StyledOrderH3>
      <StyledOrderP>
        当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
      </StyledOrderP>
      <StyledOrderP>
        空羽~kuu~ 〒247-0061神奈川県鎌倉市山ノ内1415北鎌倉アトリエキッチン
      </StyledOrderP>
      <StyledLink to="/contact">お問い合わせはこちら</StyledLink>
    </LayoutForOrder>
  );
};
const StyledLink = styled(Link)`
  text-decoration: underline;
`;
export default PraivacyPolicy;
export const Head = () => <Seo title={"プライバシーポリシー"} />;
